import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import ur from "./translations/ur";

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      ur,
    },
    lng: "ur",
    fallbackLng: ["ur", "en"],

    interpolation: {
      escapeValue: false,
    },
  });
