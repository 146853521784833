import ReactPlayer from "react-player";
import React, { useEffect, useState } from "react";

export default function VideoPlayer({ channel, onClose }) {
  const [playerState, setPlayerState] = useState({
    url: null,
    playing: false,
    muted: true,
  });

  useEffect(() => {
    setPlayerState((prevState) => ({
      ...prevState,
      ...{
        url:
          typeof channel !== "string"
            ? null
            : `/stream/content/${channel}.m3u8`,
        playing: typeof channel === "string",
      },
    }));
  }, [channel]);

  function reloadPlayer() {
    const url = playerState.url;
    const playing = playerState.playing;

    setPlayerState((prevState) => ({
      ...prevState,
      ...{
        url: null,
        playing: false,
      },
    }));

    setTimeout(() => {
      setPlayerState((prevState) => ({
        ...prevState,
        ...{
          url,
          playing,
        },
      }));
    }, 313);
  }

  function unmutePlayer() {
    setPlayerState((prevState) => ({
      ...prevState,
      ...{
        muted: false,
      },
    }));
  }

  function handleError(error, data, hlsInstance, hlsGlobal) {
    if (error === "hlsError") {
      switch (data?.details) {
        case "mediaError":
          console.error("hlsError: mediaError: Recovering");
          hlsInstance.recoverMediaError();
          break;
        case "networkError":
          console.error("hlsError: networkError: Reloading player");
          reloadPlayer();
          break;
        default:
          console.error("hlsError:", data.details, {
            self: this,
            error,
            data,
            hlsInstance,
            hlsGlobal,
          });
          reloadPlayer();
      }
    } else {
      console.error("Unhandled Error: Reloading player", {
        self: this,
        error,
        data,
        hlsInstance,
        hlsGlobal,
      });
      reloadPlayer();
    }
  }

  if (!channel) {
    return <></>;
  }

  return (
    <>
      <div
        role="alert"
        className="alert alert-secondary mx-5 my-1"
        hidden={!playerState.muted}
        dir="ltr"
        onClick={unmutePlayer}
      >
        <span className="">
          Kindly click here to allow your device to play audio.
        </span>
        <button className="btn btn-secondary">Allow</button>
      </div>

      <div
        style={{
          aspectRatio: "16/9",
          backgroundColor: "rgba(255, 255, 255, 0.33)",
        }}
      >
        <ReactPlayer
          hidden={!channel}
          url={playerState.url}
          controls={false}
          width="100%"
          height="100%"
          playing={playerState.playing}
          muted={playerState.muted}
          config={{
            file: {
              hlsOptions: {
                debug: false,
                liveDurationInfinity: true,
                backBufferLength: 0,
              },
            },
          }}
          onError={handleError}
          onEnded={reloadPlayer}
        />
      </div>
    </>
  );
}
