import useSWR from "swr";

export default function useStreamingStatus(...args) {
  return useSWR(key, (...args) => fetcher(...args), options);
}

const key = "/api/status";
const options = {
  refreshInterval: 99,
  loadingTimeout: 313,
  shouldRetryOnError: true,
  keepPreviousData: false,
};

async function fetcher(...args) {
  const r = await fetch(...args);
  return r.json();
}
