import "./styles/main.scss";
import React, { useState } from "react";
import VideoPlayer from "./components/video-player";
import ChannelCard from "./components/channel-card";
import useStreamingStatus from "./lib/live-streaming-status";

export default function App() {
  const [currentVideoChannel, setCurrentVideoChannel] = useState(null);
  const liveStreamingStatus = useStreamingStatus();

  function handleChannelSelection(ch) {
    setCurrentVideoChannel(null);
    setCurrentVideoChannel(ch);
    window.scroll(0, 0);
  }

  return (
    <>
      <header className="sticky-top">
        <nav className="navbar navbar-dark">
          <a href="/" className="navbar-brand">
            <img
              height="49"
              src="https://www.tazkia.org/ur/images/main_items.gif"
              alt=""
            />
            <span className="mx-2">نشریات</span>
            <div
              className="spinner-border text-white"
              role="status"
              hidden={!liveStreamingStatus.isValidating}
            />
          </a>
          <div className="nav me-auto ms-1">
            <button
              className="btn btn-primary"
              hidden={!currentVideoChannel}
              onClick={() => handleChannelSelection(null)}
            >
              Stop
            </button>
          </div>
        </nav>
      </header>

      <main role="main" className="mb-5">
        <section className="container-fluid">
          <VideoPlayer
            channel={currentVideoChannel}
            onClose={() => handleChannelSelection(null)}
          />
        </section>

        <section className="container">
          <ChannelCard
            status={liveStreamingStatus}
            channel="icecast-live"
            title="براہ راست اردو نشریات (ویڈیو کے بغیر)"
            schedule="وقت: &#9312; روزانہ فجر کی نماز کے بعد &#9313; روزانہ مغرب کی نماز کے بعد (سوائے جمعہ کے) &#9314; جمعہ کی نماز سے پہلے۔ بمطابق پاکستانی اوقات"
            dir="rtl"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={() =>
              openLinkInNewWindow("http://18.204.189.81:8000/live")
            }
          />
          <ChannelCard
            status={liveStreamingStatus}
            channel="icecast-live-en"
            title="Live English Broadcast (without video)"
            schedule="Time: Every Sunday 03:30pm GMT"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={() =>
              openLinkInNewWindow("http://18.204.189.81:8000/live-en")
            }
          />
          <ChannelCard
            status={liveStreamingStatus}
            channel="icecast-live-ps"
            title="لائیو پښتو براڈکاسٹ"
            schedule="وخت: هر ګل په ورځ ماځیګر نمانځه نه پس 4:10 بجے۔ پاکستانی وخت مطابق۔"
            dir="rtl"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={() =>
              openLinkInNewWindow("http://18.204.189.81:8000/live-ps")
            }
          />
          <ChannelCard
            status={liveStreamingStatus}
            channel="live-ur-video"
            title="براہ راست اردو نشریات (ویڈیو کے ساتھ)"
            schedule="وقت: &#9312; روزانہ فجر کی نماز کے بعد &#9313; روزانہ مغرب کی نماز کے بعد (سوائے جمعہ کے)۔ بمطابق پاکستانی اوقات"
            dir="rtl"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={handleChannelSelection}
          />
          <ChannelCard
            status={liveStreamingStatus}
            channel="live-en-video"
            title="Live English Broadcast (with video)"
            schedule="Time: Every Sunday 03:30pm GMT"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={handleChannelSelection}
          />
          <ChannelCard
            status={liveStreamingStatus}
            channel="trpg-video"
            title="تربیتی پروگرام"
            schedule="وقت: ہر اتوار دوپہر دو بجے"
            dir="rtl"
            thumbnail="https://7.cdn.tazkia.org/ur/assets/images/play-inactive.jpg"
            onClick={handleChannelSelection}
          />
        </section>
      </main>

      <footer className="fixed-bottom">
        <nav className="navbar navbar-dark">
          <div className="nav flex-fill justify-content-center">
            <span className="navbar-text">خانقاہ رحمکاریہ امدادیہ</span>
          </div>
        </nav>
      </footer>
    </>
  );
}

function openLinkInNewWindow(url) {
  window.open(url, "_new");
}
