import React from "react";

export default function ChannelCard({
  channel = null,
  status,
  title,
  description,
  schedule,
  dir = "ltr",
  thumbnail,
  onClick,
}) {
  const live =
    !!status &&
    !!status?.data &&
    !status?.isLoading &&
    status?.data?.channels[channel].live;
  const online = !!status && !!status?.data;

  return (
    <>
      <div
        className="card flex-row mt-3"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.26)",
          cursor: "pointer",
        }}
        onClick={() => onClick(channel)}
      >
        <img
          className="img-fluid rounded-end my-auto"
          src={thumbnail}
          width={99}
          alt=""
        />
        <div className="flex-fill">
          <div className="card-body" dir={dir}>
            <h5 className="card-title">
              {title}
              &nbsp;
              <span hidden={!online}>
                <span className="badge text-bg-primary blink" hidden={!live}>
                  Online
                </span>
                <span className="badge text-bg-secondary" hidden={live}>
                  Offline
                </span>
              </span>
              <span hidden={online}>
                <span className="badge text-bg-secondary">Connecting</span>
              </span>
            </h5>
            <p className="card-text">
              <small className="text-body-primary">{description}</small>
            </p>
            <p className="card-text">
              <small className="text-body-secondary">{schedule}</small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
